import React from "react";
import "../Voice-heard/Voice-heard.css"
export default function MakeYourVoiceHeard() {

    const redirectAppStore = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://itunes.apple.com/app/your-app-id';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        }
    };
    return (
        <div class="voice-heard-banner ">
            <div class="voice-heard-wrapper">
                <h1>Make your Voice Heard!</h1>
                <p>Make your voice loud and clear. Join our community platform - where every opinion makes a difference.</p>
                <div class="bottom-download">
                    <button class="download-button" onClick={redirectAppStore}>
                        <span>Download</span>
                        <img src="/Images/download1.svg" alt="download-icon"></img></button>
                </div>
            </div>
        </div>

    )
}