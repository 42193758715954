import React, { useEffect } from 'react';
import Navbar from '../../Common-components/Navbar/Navbar.js'
import Footer from '../../Common-components/Footer/Footer.js'
import MakeYourVoiceHeard from "../../Common-components/Voice-heard/Voice-heard.js";
import "../Features/Feature.css";
export default function Feature() {
    const redirectAppStore = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://itunes.apple.com/app/your-app-id';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        }
    };
    const reveal = () => {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 10;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", reveal);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", reveal);
        };
    }, []);
    return (
        <>
            <Navbar />
            <div className="helpNationContainer">
                <div className="banner3">

                    <div className="leftContainerWrapper">
                        <div className="leftContainerTopParagraph">
                            <p>FEATURES</p>
                        </div>
                        <div className="leftContainerHeader">
                            <h1>
                                Help your nation
                                <br />
                                grow as Democracy
                            </h1>
                        </div>
                        <div className="leftContainerBottomParagraph">
                            <p>
                                Join the movement that empowers youth voices and help shape a
                                better democracy for future generation of your country
                            </p>
                        </div>
                        <div className="download-wrapper">
                            <button
                                className="download-button-features"
                                onClick={redirectAppStore}
                            >
                                <span>Download</span>
                                <img src="/Images/download.svg" alt="download-icon" />
                            </button>
                        </div>
                    </div>

                    <div className="banner-conatent-image">
                        <img src="/Images/feature-mobile.png" alt="home-image"></img>
                    </div>
                </div>
            </div>
            <section className="instruction-container1 reveal">
                <h1>Help your Nation Grow</h1>
                <p>Make democracy great again</p>
                <div className="instruction-wrapper">
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge3.svg" alt="Badge3" />
                        <h4> Download the app</h4>
                        <p>Download the application from play store or apple store </p>
                    </div>
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge4.svg" alt="Badge3" />
                        <h4> Scan your documents</h4>
                        <p>Scan your government ID to verify your profile in app </p>
                    </div>
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge5.svg" alt="Badge3" />
                        <h4> Select your interest</h4>
                        <p>
                            Select your interests from the list of topics to see in your feed{" "}
                        </p>
                    </div>
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge6.svg" alt="Badge3" />
                        <h4> Be a part of the community</h4>
                        <p>Enjoy being a part of the community and make your voice heard </p>
                    </div>
                </div>
            </section>
            <div className="fuelTheFire reveal">
                <div className="banner4">
                    <div className="fuelTheFireWrapper">
                        <h1>Fuel the fire, Unleash your voice, Ignite the change</h1>
                        <p>
                            Join the movement that empowers youth voices and help shape a better
                            <br />
                            democracy for future generation of your country
                        </p>
                        <a href="/">
                            <div className="joinNow">Join Now! </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="diveIntoInterestContainer reveal">
                <div className="image-interest">
                    <img src="/Images/1.svg" alt="Interest" />
                </div>
                <div className="diveIntoInterest">
                    <div className="diveIntoInterestHeader">
                        <h1>Dive into interests</h1>
                    </div>
                    <div className="diveIntoInterestParagraph">
                        <p>
                            Advocate for your interest, contribute ideas and collaborate with
                            fellow embers to drive positive change. Actively participate in
                            shaping community decisions through democratic discussions and polls.
                        </p>
                    </div>
                </div>
            </div>
            <div className="createPollsContainer reveal ">
                <div className="createPolls">
                    <div className="createPollsHeader">
                        <h1>Create Polls</h1>
                    </div>
                    <div className="createPollsParagraph">
                        <p>
                            Shape decisions effortlessly - Create and participate in poll. Your
                            opinion matter, so make your voice counts in the democratic dialogue.
                            Engage, decide and contribute to community growth.
                        </p>
                    </div>
                </div>
                <div className="imageContainer3">
                    <img src="/Images/9.svg" alt="Interest" />
                </div>
            </div>
            <div className="diveIntoInterestContainer reveal">
                <div className="image-share">
                    <img src="/Images/3.svg" alt="Interest" />
                </div>
                <div className="share">
                    <div className="diveIntoInterestHeader">
                        <h1>Share your opinion</h1>
                    </div>
                    <div className="diveIntoInterestParagraph">
                        <p>
                            Join the conversations, share your unique perspective. Your opinions
                            matter - engage, discuss and be part of democratic dialogue. Shape the
                            community narrative by raising your voice.
                        </p>
                    </div>
                </div>
            </div>
            <div className="createPollsContainer reveal">
                <div className="createPolls">
                    <div className="createPollsHeader">
                        <h1>Unveiling Key Insights from Polls</h1>
                    </div>
                    <div className="createPollsParagraph">
                        <p>
                            Discover community consensus with our poll insights. Uncover trends,
                            gauge opinions, and understand perspectives at a glance. Fuel informed
                            decisions by your participation.
                        </p>
                    </div>
                </div>
                <div className="imageContainer1">
                    <img src="/Images/4.svg" alt="Interest" />
                </div>
            </div>
            <div className="diveIntoInterestContainer reveal">
                <div className="image-role">
                    <img src="/Images/5.svg" alt="Interest" />
                </div>
                <div className="diveIntoInterest">
                    <div className="diveIntoInterestHeader">
                        <h1>Role - Based management</h1>
                    </div>
                    <div className="diveIntoInterestParagraph">
                        <p>
                            Empower your community with role based management. Assign roles,
                            Streamline responsibilities, and cultivate effective collaboration
                            within your dynamic digital space.
                        </p>
                    </div>
                </div>
            </div>
            <div className="createPollsContainer reveal">
                <div className="join">
                    <div className="createPollsHeader">
                        <h1>Send Bulk Invitation</h1>
                    </div>
                    <div className="createPollsParagraph">
                        <p>
                            Create and join events effortlessly! Organize exciting gathering and
                            invite members in bulk, fostering community engagement at its best.{" "}
                        </p>
                    </div>
                </div>
                <div className="image-join">
                    <img src="/Images/6.svg" alt="Interest" />
                </div>
            </div>
            <MakeYourVoiceHeard />
            <Footer />
        </>

    )

}