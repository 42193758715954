import React from "react";
import "../Footer/Footer.css";

export default function Footer() {
    const redirectAppStore = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://itunes.apple.com/app/your-app-id';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        }
    };
    return (
        <div className="footer-container">
            <div className="left-footer">
                <div className="logo1">
                    <a href="/">
                        <img className="logo-img1" src="/Images/drudge-logo.png" alt="logo" />
                    </a>
                </div>
                <p>Join Interested Communities and Make Your Voice heard!!</p>
                <button className="download-outline-button" onClick={redirectAppStore}>
                    <span>Download</span>
                    <img src="/Images/download.svg" alt="download-icon" />
                </button>
            </div>
            <div className="right-footer">
                <h4>QUICK LINK</h4>
                <div className="footer-navigation">
                    <div className="left-ul">
                        <ul>
                            <li>
                                <a href="/">Homepage</a>
                            </li>
                            <li>
                                <a href="/features">Features</a>
                            </li>
                        </ul>
                    </div>
                    <div className="right-ul">
                        <ul>
                            <li>
                                <a href="/policy">Policy</a>
                            </li>
                            <li>
                                <a href="/support">Help </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
