import React from "react";
import Navbar from '../../Common-components/Navbar/Navbar.js'
import Footer from '../../Common-components/Footer/Footer.js'
import MakeYourVoiceHeard from "../../Common-components/Voice-heard/Voice-heard.js";
import "../Policy/Policy.css";

export default function PrivacyPolicy() {
    return (
        <>
            <div className="privacy-container">
                <Navbar />
                <div className="privacy-banner">
                    <header-wrapper>
                        <h1>Privacy Policy</h1>
                        <p>Effective from : December 23, 2023</p>
                    </header-wrapper>
                </div>
                <div className="privacy-banner-details">
                    <div className="privacy-banner-data">
                        <div className="temp ">
                            <h1>Privacy Policy</h1>
                            <p>
                                This Privacy Policy describes Our policies and procedures on the
                                collection, use and disclosure of Your information when You use the
                                Service and tells You about Your privacy rights and how the law
                                protects You.
                            </p>
                            <br />
                            <p>
                                We use Your Personal data to provide and improve the Service. By using
                                the Service, You agree to the collection and use of information in
                                accordance with this Privacy Policy.
                            </p>
                        </div>
                        <div className="tc-container">
                            <div className="tc-content-box ">
                                <h1>Terms And Conditions</h1>
                                <h2>Introduction and Data Protection</h2>
                                <p>
                                    By using our service, you consent to furnishing specific personal
                                    information, including your name, date of birth, gender, and mobile
                                    number, for the purpose of identity verification. We prioritize your
                                    privacy and will only gather the essential data needed for user
                                    authentication and identification. Your information, such as name,
                                    date of birth, gender, and mobile number, will be securely stored.
                                </p>
                                <br />
                                <p>
                                    {" "}
                                    We might ask for permission to access your location, files, and
                                    camera to enable specific features of our app. Rest assured, we'll
                                    always seek your consent before utilizing these features, and your
                                    location data will solely be used to suggest nearby events and
                                    relevant recommendations.
                                </p>
                                <br />
                                <p>
                                    {" "}
                                    Your location data, along with any other personal information, will
                                    never be shared with third parties without your explicit approval.
                                    We'll exclusively handle your personal data for the intended
                                    purposes of its collection. Your data won't be utilized for any
                                    other purposes Safeguarding your personal data is our priority, and
                                    we've implemented strong security measures to ensure its protection.
                                    If you have any concerns or questions about your data privacy,
                                    please do not hesitate to contact us.
                                </p>
                            </div>
                            <div className="tc-content-box ">
                                <h2>User Content and Conduct</h2>
                                <p>
                                    You bear responsibility for the content you share on our app. It's
                                    essential to ensure that your content aligns with our community
                                    guidelines and adheres to all relevant laws and regulations. We
                                    retain the right to remove any content that breaches our guidelines
                                    or infringes upon the rights of others.
                                </p>
                            </div>
                            <div className="tc-content-box ">
                                <h2>Intellectual Property</h2>
                                <p>
                                    All the content within our app, encompassing text, graphics, polls,
                                    and images, belongs to us or our licensors. The usage of our content
                                    requires explicit permission from us or the relevant licensor.
                                </p>
                            </div>
                            <div className="tc-content-box ">
                                <h2>Prohibited Activities</h2>
                                <p>
                                    This section delineates activities that are strictly prohibited on
                                    our app. Users are not permitted to partake in any illegal or
                                    harmful activities, including but not limited to spamming, hacking,
                                    or distributing malware. Impersonation, harassment, intimidation, or
                                    bullying of other users through our app is also strictly forbidden
                                </p>
                            </div>
                            <div className="tc-content-box ">
                                <h2>Limitation of Liability and indemnification</h2>
                                <p>
                                    Upon accepting these terms and conditions, users consent to absolve
                                    us of any claims, damages, or liabilities that may arise from their
                                    use of our app or any violation of these terms and conditions. We
                                    bear no liability for damages stemming from the use or inability to
                                    use our app. Additionally, we are not accountable for any indirect,
                                    incidental, or consequential damages that may occur. This section
                                    emphasizes that users are solely responsible for the risks
                                    associated with using our app.
                                </p>
                            </div>
                            <div className="tc-content-box ">
                                <h2>Modification and Termination</h2>
                                <p>
                                    We retain the right to amend these terms and conditions at any time
                                    incompliance with GDPR requirements. User's will be informed of any
                                    changes via our app or website. This section aims to ensure users
                                    are informed about modifications to the terms and conditions and
                                    underscores that their continued use of the app following changes
                                    constitutes acceptance of the updated terms and conditions.
                                </p>
                                <br />
                                <p>
                                    {" "}
                                    We also reserve the right to terminate a user's access to our app at
                                    any time if they violate these terms and conditions or our community
                                    guidelines. This section makes it clear that we have the authority
                                    to remove a users access to our app if they are not following the
                                    rules outlined in the terms and conditions or if they are violating
                                    our community guidelines.
                                </p>
                            </div>
                            <div className="tc-content-box ">
                                <h2>Data Subject Rights</h2>
                                <p>
                                    According to GDPR, you possess specific rights concerning your
                                    personal data. These rights encompass the ability to access,
                                    rectify, erase, restrict, and object to the processing of your
                                    information has context menu
                                </p>
                            </div>
                        </div>
                        <div className="tc-content-box ">
                            <h6 className="email">
                                For additional questions:{" "}
                                <a className="mail" href="mailto:info@drudge.com">
                                    {" "}
                                    <u>info@drudge.com</u>
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>
                <MakeYourVoiceHeard />
                <Footer />
            </div>
        </>
    );
}
