import React, { useEffect } from 'react';
import "../Home/Home.css";
import Navbar from '../../Common-components/Navbar/Navbar.js'
import Footer from '../../Common-components/Footer/Footer.js'
import MakeYourVoiceHeard from "../../Common-components/Voice-heard/Voice-heard.js";
export default function Home() {

    const redirectAppStore = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://itunes.apple.com/app/your-app-id';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        }
    };
    const reveal = () => {
        var reveals = document.querySelectorAll(".reveal");
    
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 10;
    
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      };
      useEffect(() => {
        window.addEventListener("scroll", reveal);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          window.removeEventListener("scroll", reveal);
        };
      }, []); 

    return (
        <>
            <Navbar />
            <section className="home-banner ">
                <div className="banner-container">
                    <div className="banner-content">
                        <h1>
                            Join the community <br />
                            and make your
                            <br />
                            <span>Voice Heard !</span>
                        </h1>
                        <p>
                            Contribute to your nations growth! Engage in democratic
                           
                            community, share ideas, and shape the future together.
                        </p>
                        <button
                            className="download-outline-button1"
                            onClick={redirectAppStore}>
                            <span>Download</span>
                            <img src="/Images/download.svg" alt="download-icon" />
                        </button>
                    </div>
                    <div className="banner-conatent-image">
                        <img src="/Images/home-mobile.png" alt="home-image"></img>
                    </div>
                </div>

            </section>
            {/* how its works  */}
            <section className="instruction-container reveal">
                <h1>Help your Nation Grow</h1>
                <p>Make democracy great again</p>
                <div className="instruction-wrapper">
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge3.svg" alt="Badge3" />
                        <h4> Download the app</h4>
                        <p>Download the application from play store or apple store </p>
                    </div>
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge4.svg" alt="Badge3" />
                        <h4> Scan your documents</h4>
                        <p>Scan your government ID to verify your profile in app </p>
                    </div>
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge5.svg" alt="Badge3" />
                        <h4> Select your interest</h4>
                        <p>
                            Select your interests from the list of topics to see in your feed{" "}
                        </p>
                    </div>
                    <div className="instruction-wrapper-internal">
                        <img src="/Images/badge6.svg" alt="Badge3" />
                        <h4> Be a part of the community</h4>
                        <p>Enjoy being a part of the community and make your voice heard </p>
                    </div>
                </div>
            </section>
            {/* one nation body */}
            <section className="one-nation-container reveal ">
                <div className="features ">
                    <h1>
                        One Nation.
                        <br />
                        One Democracy.
                    </h1>
                    <div className="features-list">
                        <div className="features-list-internal">
                            <img src="/Images/nation-logo1.svg" alt="badge" />
                            <h3>Vote on Polls</h3>
                            <p>
                                Bite poles, shout loud - Change begins with the voice of you echo
                            </p>
                        </div>
                        <div className="features-list-internal">
                            <img src="/Images/nation-logo2.svg" alt="badge" />
                            <h3>Community based</h3>
                            <p>
                                Amplify concerns, foster dialogue, and ignite changes together as a
                                community
                            </p>
                        </div>
                        <div className="features-list-internal">
                            <img src="/Images/nation-logo3.svg" alt="badge" />
                            <h3>Role based community</h3>
                            <p>
                                Assign roles, Shape influence. Admins lead, contributers amplify{" "}
                            </p>
                        </div>
                        <div className="features-list-internal">
                            <img src="/Images/nation-logo4.svg" alt="badge" />
                            <h3>Share your opinion</h3>
                            <p>
                                Express yourself, spark a change. Join the dialogue and make a
                                difference
                            </p>
                        </div>
                        <div className="features-list-internal">
                            <img src="/Images/nation-logo5.svg" alt="badge" />
                            <h3>Bulk invitation</h3>
                            <p>
                                Invite in bulk. Seamlessly bring your community together. More
                                voices, more impact{" "}
                            </p>
                        </div>
                        <div className="features-list-internal">
                            <img src="/Images/nation-logo6.svg" alt="badge" />
                            <h3>Share on your socials</h3>
                            <p>
                                Spread the word. Share your community. Let’s amplify voices and make
                                a difference{" "}
                            </p>
                        </div>
                    </div>
                    <a href="/features">
                        <button className="know-more">Know More</button>
                    </a>
                </div>
                <div className="features-image">
                    <img src="/Images/feature-image.svg" alt="Group" />
                </div>
            </section>
            {/* help content */}
            <section className="help-your-nation-container reveal">
                <div className="help-content-wrapper">
                    <h1>Help your nation grow as a democratic community</h1>
                    <p>
                        Contribute to your nations growth! Engage in democratic community, share
                        ideas, and shape the future together.
                    </p>
                    <div className="growth-list-wrapper">
                        <div className="growth-list-wrapper-internal">
                            <img src="/Images/badge1.svg" alt="badge" />
                            <p>Vote on most important topics</p>
                        </div>
                        <div className="growth-list-wrapper-internal">
                            <img src="/Images/badge1.svg" alt="badge" />
                            <p>Raise your voice</p>
                        </div>
                        <div className="growth-list-wrapper-internal">
                            <img src="/Images/badge1.svg" alt="badge" />
                            <p>Share on your Socials</p>
                        </div>
                    </div>
                </div>
                <div className="help-content-wrapper-img">
                    <img src="/Images/community-image.svg" alt="Group" />
                </div>
            </section>
            <MakeYourVoiceHeard />
            <Footer />
        </>

    );
}
