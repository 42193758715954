import React from "react";
import Navbar from '../../Common-components/Navbar/Navbar.js'
import Footer from '../../Common-components/Footer/Footer.js'
import MakeYourVoiceHeard from "../../Common-components/Voice-heard/Voice-heard.js";
import "../Delete-account/Delete-account.css";

export default function DeleteAccount() {
    return (
        <>
           <div class="privacy-container">
            <Navbar />
            <div className="privacy-banner1">
                <header-wrapper>
                    <h1>How to delete your account?</h1>
                </header-wrapper>
            </div>
            <div className="privacy-banner-details ">
                <div className="privacy-banner-data">
                    <div className="temp">
                        <h1>Delete your account</h1>
                        <p>
                            When you <span>delete your account</span>, your profile, photos,
                            videos, comments, likes and followers will be permanently removed.
                        </p>
                    </div>
                    <div className="tc-container">
                        <div className="tc-content-box ">
                            <h2>What happens if I permanently delete my Drudge account?</h2>
                            <ul className="limitation">
                                <li>You won't be able to reactivate your account.</li>
                                <li>
                                    Your profile, photos, posts, videos and everything else you've
                                    added will be permanently deleted. You won't be able to retrieve
                                    anything you've added.
                                </li>
                                <li>You'll no longer be able to use Drudge application.</li>
                                <li>
                                    Some information, such as messages you sent to friends, may still
                                    be visible to them after you've deleted your account. Copies of
                                    messages that you've sent are stored in your friends' inboxes.
                                </li>
                            </ul>
                        </div>
                        <div className="tc-content-box ">
                            <h2>Learn how to permanently delete your Drudge account.</h2>
                            <h3>To permanently delete your Drudge account:</h3>
                            <ul className="instruction">
                                <li>
                                    Initiate the account deletion process through e-mailing us the
                                    request for account deletion.
                                </li>
                                <li>Provide a reason for it.</li>
                                <li>
                                    You will receive an e-mail when the account would be deleted.
                                </li>
                            </ul>
                        </div>
                        <div className="tc-content-box">
                            <h2>Can I cancel my account deletion?</h2>
                            <p>
                                If it's been less than 30 days since you initiated the deletion, you
                                can cancel your account deletion. After 30 days, your account and
                                all of your information will be permanently deleted, and you won't
                                be able to retrieve your information.
                                <br />
                                It may take up to 90 days from the beginning of the deletion process
                                to delete all the things you've posted. While we're deleting this
                                information, it's not accessible to other people using drudge.
                                <br />
                                Copies of your information may remain after the 90 days in backup
                                storage that we use to recover in the event of a disaster, software
                                error or other data loss event. We may also keep your information
                                for things such as legal issues, terms violations or harm prevention
                                efforts. Learn more about our&nbsp;Privacy Policy.
                            </p>
                            <h4>To cancel your account deletion:</h4>
                            <ul className="steps">
                                <li>
                                    Log in to your drudge account within 30 days of deleting your
                                    account.
                                </li>
                                <li>Click&nbsp;Cancel Deletion.</li>
                            </ul>
                        </div>
                        <div className="tc-content-box ">
                            <h6 className="email">
                                For additional questions:{" "}
                                <a className="mail" href="mailto:info@drudge.com">
                                    {" "}
                                    <u>info@drudge.com</u>
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <MakeYourVoiceHeard />
            <Footer />
            </div>
        </>
    );
}