import React from "react";
import Navbar from '../../Common-components/Navbar/Navbar.js'
import Footer from '../../Common-components/Footer/Footer.js'
import "../Support/Support.css";

export default function Support() {
    return (
        <>
            <Navbar />
            <section className="support">
                <div className="support-wrapper">
                    <h1>How can we help you?</h1>
                    <h2>Popular Topics</h2>
                    <div className="get-help">
                        <img src="/Images/get-help-icon.svg" alt="get-help-icon" />
                        <div className="get-help-content">
                            <h3>How do I delete my account?</h3>
                            <p>
                                Learn what to do if you’re having trouble deleting your account on
                                drudge.
                            </p>
                        </div>
                        <a href="/delete-account">
                            <button className="get-help-button">Get Help</button>
                        </a>
                    </div>
                    <div className="get-help-option">
                        <div className="internal-option">
                            <a href="/policy">
                                <div className="internal-option-img">
                                    <img src="/Images/privacy-icon.svg" alt="privacy-icon" />
                                </div>
                                <h3>Privacy and Security</h3>
                                <p>
                                    Control who can see what you share and add extra protection to your
                                    account.
                                </p>
                            </a>
                        </div>
                        <div className="internal-option">
                            <a href="/delete-account">
                                <div className="internal-option-img">
                                    <img src="/Images/setting-icon.svg" alt="privacy-icon" />
                                </div>
                                <h3>Delete Account </h3>
                                <p>
                                    Adjust settings, manage notifications, learn about account deletion
                                    and more.
                                </p>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}