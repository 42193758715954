import React from 'react';
import "../Navbar/Navbar.css";

const NavBar = () => {
    const toggleMediaIcons = () => {
        const menuLink = document.querySelector('.menu-link');
        menuLink.classList.toggle('mobile-menu-link');
    };

    const redirectAppStore = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://itunes.apple.com/app/your-app-id';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.drudge.android&hl=en-IN';
        }
    };

    return (
        <nav className="main-nav">
            {/* 1st logo part */}
            <div className="logo">
                <a href="/">
                    <img className="logo-img" src="/Images/drudge_logo.png" alt="logo" />
                </a>
            </div>

            {/* 2nd menu part */}
            <div className="menu-link">
                <ul>
                    <li>
                        <a href="/features">Features</a>
                    </li>
                    <li>
                        <a href="/policy">Policy</a>
                    </li>
                    <li>
                        <a href="/support">Support</a>
                    </li>
                    <div className="download">
                        <button className="download-button" onClick={redirectAppStore}>
                            <span>Download</span>
                            <img src="/Images/download1.svg" alt="download-icon" />
                        </button>
                    </div>
                </ul>
            </div>

            {/* hamburger menu */}
            <div className="hamburger-container">
                {/* hamburger menu start */}
                <div className="hamburger-menu">
                    <a onClick={toggleMediaIcons}>
                        <img src="/Images/nav-logo.png" alt="hamburger-menu" style={{ width: '70%' }} />
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;