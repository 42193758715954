import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Components/Home/Home.js';
import Feature from './Components/Features/Feature.js';
import Support from './Components/Support/Support';
import PrivacyPolicy from './Components/Policy/Policy.js';
import DeleteAccount from './Components/Delete-account/Delete-account.js';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="features" element={<Feature />} />
        <Route path="policy" element={<PrivacyPolicy />} /> 
        <Route path="support" element={<Support />} />
        <Route path="delete-account" element={<DeleteAccount />} />
        
      </Routes>
    </Router>
  </React.StrictMode>
);